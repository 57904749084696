import {
  //SAVE
    LOT_SAVE_FAILED,
    LOT_SAVE_SUCCESS,
    LOT_IS_SAVING,
    //LOAD
    LOT_FETCH_FAILED,
    LOT_FETCH_SUCCESS,
    LOT_IS_FETCHING,
    //UPDATE
    LOT_IS_UPDATING,
    LOT_UPDATE_FAILED,
    LOT_UPDATE_SUCCESS,
    //DELETE
    LOT_DELETE_SUCCESS,
    LOT_DELETE_FAILED,
    LOT_IS_DELETING,
    //PATCH
    LOT_IS_PATCHING,
    LOT_PATCH_FAILED,
    LOT_PATCH_SUCCESS
   
  } from "../types/lotTypes";
  
  var initialState = {
   lotSaved: false,
   lotIsSaving: false,
   lotIsFetching: false,
   lotUpdated: false,
   lotIsUpdating: false,
   lotPatched: false,
   lotIsPatching: false,
   lotDeleted: false,
   lotIsDeleting: false,
    error: null,
   lots: [],
   lot:{}
  };
  
  const lotReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case LOT_IS_SAVING:
        return {
          ...state,
          lotIsSaving: true,
          error: null
        };
      case LOT_SAVE_SUCCESS:
        return {
          ...state,
          lotSaved: true,
          lotIsSaving: false,
          error: null,
          lot:action.payload
        };
      case LOT_SAVE_FAILED:
        return {
          ...state,
          lotIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case LOT_IS_FETCHING:
        return { ...state,
          lotIsFetching: true,
          error: null };
      case LOT_FETCH_SUCCESS:
        return {
          ...state,
          lotIsFetching: false,
          lots: action.payload,
          error: null
        };
      case LOT_FETCH_FAILED:
        return {
          ...state,
          lotIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case LOT_IS_UPDATING:
        return { ...state, lotIsUpdating: true,error: null };
      case LOT_UPDATE_SUCCESS:
        return {
          ...state,
          lotUpdated: true,
          lotIsUpdating: false,
          error: null
        };
      case LOT_UPDATE_FAILED:
        return {
          ...state,
          lotIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case LOT_IS_PATCHING:
        return { ...state, lotIsPatching: true,error: null };
      case LOT_PATCH_SUCCESS:
        return {
          ...state,
          lotPatched: true,
          lotIsPatching: false,
          error: null
        };
      case LOT_PATCH_FAILED:
        return {
          ...state,
          lotIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case LOT_IS_DELETING:
      return { ...state, lotIsDeleting: true ,error: null};
    case LOT_DELETE_SUCCESS:
      return {
        ...state,
        lotDeleted: true,
        lotIsDeleting: false,
        error: null
      };
    case LOT_DELETE_FAILED:
      return {
        ...state,
        lotIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default lotReducer;
  
  