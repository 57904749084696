/*-----------Types of Lot actions--------*/

/*---SAVE----*/
export const LOT_SAVE_SUCCESS = "LOT_SAVE_SUCCESS";
export const LOT_SAVE_FAILED = "LOT_SAVE_FAILED";
export const LOT_IS_SAVING = "LOT_IS_SAVING";
/*---FETCH----*/
export const LOT_FETCH_SUCCESS = "LOT_FETCH_SUCCESS";
export const LOT_FETCH_FAILED = "LOT_FETCH_FAILED";
export const LOT_IS_FETCHING = "LOT_IS_FETCHING";

/*---UPDATE----*/
export const LOT_UPDATE_SUCCESS = "LOT_UPDATE_SUCCESS";
export const LOT_UPDATE_FAILED = "LOT_UPDATE_FAILED";
export const LOT_IS_UPDATING = "LOT_IS_UPDATING";

/*---PATCH----*/
export const LOT_PATCH_SUCCESS = "LOT_PATCH_SUCCESS";
export const LOT_PATCH_FAILED = "LOT_PATCH_FAILED";
export const LOT_IS_PATCHING = "LOT_IS_PATCHING";


/*---DELETE----*/
export const LOT_DELETE_SUCCESS = "LOT_DELETE_SUCCESS";
export const LOT_DELETE_FAILED = "LOT_DELETE_FAILED";
export const LOT_IS_DELETING = "LOT_IS_DELETING";