import {
  //SAVE
    STAGE_SAVE_FAILED,
    STAGE_SAVE_SUCCESS,
    STAGE_IS_SAVING,
    //LOAD
    STAGE_FETCH_FAILED,
    STAGE_FETCH_SUCCESS,
    STAGE_IS_FETCHING,
    //UPDATE
    STAGE_IS_UPDATING,
    STAGE_UPDATE_FAILED,
    STAGE_UPDATE_SUCCESS,
    //DELETE
    STAGE_DELETE_SUCCESS,
    STAGE_DELETE_FAILED,
    STAGE_IS_DELETING,
    //PATCH
    STAGE_IS_PATCHING,
    STAGE_PATCH_FAILED,
    STAGE_PATCH_SUCCESS
   
  } from "../types/stageTypes";
  
  var initialState = {
    stageSaved: false,
    stageIsSaving: false,
    stageIsFetching: false,
    stageUpdated: false,
    stageIsUpdating: false,
    stagePatched: false,
    stageIsPatching: false,
    stageDeleted: false,
    stageIsDeleting: false,
    error: null,
    stages: [],
    stage:{}
  };
  
  const stageReducer = (state = initialState, action) => {
    switch (action.type) {
      //-------Save-------//
      case STAGE_IS_SAVING:
        return {
          ...state,
          stageIsSaving: true,
          error: null
        };
      case STAGE_SAVE_SUCCESS:
        return {
          ...state,
          stageSaved: true,
          stageIsSaving: false,
          error: null,
          stage:action.payload
        };
      case STAGE_SAVE_FAILED:
        return {
          ...state,
          stageIsSaving: false,
          error: action.payload
        };
  
      //-------Fetch-------//
      case STAGE_IS_FETCHING:
        return { ...state,
          stageIsFetching: true,
          error: null };
      case STAGE_FETCH_SUCCESS:
        return {
          ...state,
          stageIsFetching: false,
          stages: action.payload,
          error: null
        };
      case STAGE_FETCH_FAILED:
        return {
          ...state,
          stageIsFetching: false,
          error: action.payload
        };
  
      //-------update-------//
      case STAGE_IS_UPDATING:
        return { ...state, stageIsUpdating: true,error: null };
      case STAGE_UPDATE_SUCCESS:
        return {
          ...state,
          stageUpdated: true,
          stageIsUpdating: false,
          error: null
        };
      case STAGE_UPDATE_FAILED:
        return {
          ...state,
          stageIsUpdating: false,
          error: action.payload
        };
        //-------patch-------//
      case STAGE_IS_PATCHING:
        return { ...state, stageIsPatching: true,error: null };
      case STAGE_PATCH_SUCCESS:
        return {
          ...state,
          stagePatched: true,
          stageIsPatching: false,
          error: null
        };
      case STAGE_PATCH_FAILED:
        return {
          ...state,
          stageIsPatching: false,
          error: action.payload
        };
        //-------Delete-------//
    case STAGE_IS_DELETING:
      return { ...state, stageIsDeleting: true ,error: null};
    case STAGE_DELETE_SUCCESS:
      return {
        ...state,
        stageDeleted: true,
        stageIsDeleting: false,
        error: null
      };
    case STAGE_DELETE_FAILED:
      return {
        ...state,
        stageIsDeleting: false,
        error: action.payload
        
      };
  
      default:
        return state;
    }
  };
  export default stageReducer;
  
  