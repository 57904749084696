/*-----------Types of STAGE actions--------*/

/*---SAVE----*/
export const STAGE_SAVE_SUCCESS = "STAGE_SAVE_SUCCESS";
export const STAGE_SAVE_FAILED = "STAGE_SAVE_FAILED";
export const STAGE_IS_SAVING = "STAGE_IS_SAVING";
/*---FETCH----*/
export const STAGE_FETCH_SUCCESS = "STAGE_FETCH_SUCCESS";
export const STAGE_FETCH_FAILED = "STAGE_FETCH_FAILED";
export const STAGE_IS_FETCHING = "STAGE_IS_FETCHING";

/*---UPDATE----*/
export const STAGE_UPDATE_SUCCESS = "STAGE_UPDATE_SUCCESS";
export const STAGE_UPDATE_FAILED = "STAGE_UPDATE_FAILED";
export const STAGE_IS_UPDATING = "STAGE_IS_UPDATING";

/*---PATCH----*/
export const STAGE_PATCH_SUCCESS = "STAGE_PATCH_SUCCESS";
export const STAGE_PATCH_FAILED = "STAGE_PATCH_FAILED";
export const STAGE_IS_PATCHING = "STAGE_IS_PATCHING";


/*---DELETE----*/
export const STAGE_DELETE_SUCCESS = "STAGE_DELETE_SUCCESS";
export const STAGE_DELETE_FAILED = "STAGE_DELETE_FAILED";
export const STAGE_IS_DELETING = "STAGE_IS_DELETING";